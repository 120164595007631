<!-- Slider -->
<div class="banner-one" style="background-image: url(assets/images/main-slider/slider1/pic2.png);" id="main">
    <div class="container">
        <div class="banner-inner">
            <div class="img1"><img src="/assets/images/main-slider/slider1/pic3.png" alt=""></div>
            <div class="img2"><img src="/assets/images/main-slider/slider1/pic4.png" alt=""></div>
            <div class="row align-items-center">
                <div class="col-md-7">
                    <div class="banner-content">
                        <h6 data-wow-duration="1s" data-wow-delay="0.5s" class="wow fadeInUp sub-title">Yenilikçi Teknolojilerle Geleceği Keşfedin</h6>
                        <h1 data-wow-duration="1.2s" data-wow-delay="1s" class="wow fadeInUp">Teknoloji Gelişir, <span class="text-primary">Dünya</span> Değişir.</h1>
                        <p  data-wow-duration="1.4s" data-wow-delay="1.5s" class="wow fadeInUp m-b30">
							BFY Digital olarak, müşterilerimizin iş ihtiyaçlarına özel çözümler sunarak, inovasyon ve özelleştirme konusundaki uzmanlığımızı her projede ortaya koyuyoruz. Yeni nesil yazılım teknolojileri kullanarak iş süreçlerinizi optimize ederken, müşterilerimize yüksek performans ve verimlilik sağlayacak çözümler sunmayı hedefliyoruz.
						</p>
                        <a  data-wow-duration="1.6s" data-wow-delay="2s" class="wow fadeInUp btn btn-primary text-white" href="#projects" (click)="goSection($event, 'projects')">
                            Neler Yaptık<i class="fa fa-angle-right m-l10 text-white"></i>
                        </a>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="dz-media move-box wow fadeIn" data-wow-duration="1.6s" data-wow-delay="0.8s" >
                        <img class="move-1" src="/assets/images/main-slider/slider1/pic5.png" alt=""/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>