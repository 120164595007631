<!-- Clients Logo -->
<div class="content-inner-2">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-12 wow fadeInRight d-grid justify-content-around" data-wow-duration="2s" data-wow-delay="0.4s">
                <div class="section-head style-1 mb-4">
                    <h6 class="sub-title">Son zamanlarda çalıştığımız öne çıkan markalar</h6>
                </div>
            </div>
        </div>
        <div class="clients-carousel owl-none owl-carousel">
            <div class="item">
                <div class="clients-logo">
                    <img class="logo-main" src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/logo/agabey.png" alt="">
                    <img class="logo-hover" src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/logo/agabey.png" alt="">
                </div>
            </div>
            <div class="item">
                <div class="clients-logo">
                    <img class="logo-main" src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/logo/akbank.svg" alt="">
                    <img class="logo-hover" src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/logo/akbank.svg" alt="">
                </div>
            </div>
            <div class="item">
                <div class="clients-logo">
                    <img class="logo-main" src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/logo/aksigorta.png" alt="">
                    <img class="logo-hover" src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/logo/aksigorta.png" alt="">
                </div>
            </div>
            <div class="item">
                <div class="clients-logo">
                    <img class="logo-main" src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/logo/Aktif_Bank_logo.svg" alt="">
                    <img class="logo-hover" src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/logo/Aktif_Bank_logo.svg" alt="">
                </div>
            </div>
            <div class="item">
                <div class="clients-logo">
                    <img class="logo-main" src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/logo/alarko.png" alt="">
                    <img class="logo-hover" src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/logo/alarko.png" alt="">
                </div>
            </div>
            <div class="item">
                <div class="clients-logo">
                    <img class="logo-main" src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/logo/Alternatif_Bank_logo.png" alt="">
                    <img class="logo-hover" src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/logo/Alternatif_Bank_logo.png" alt="">
                </div>
            </div>
            <div class="item">
                <div class="clients-logo">
                    <img class="logo-main" src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/logo/bkm.png" alt="">
                    <img class="logo-hover" src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/logo/bkm.png" alt="">
                </div>
            </div>
            <div class="item">
                <div class="clients-logo">
                    <img class="logo-main" src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/logo/calik.png" alt="">
                    <img class="logo-hover" src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/logo/calik.png" alt="">
                </div>
            </div>
            <div class="item">
                <div class="clients-logo">
                    <img class="logo-main" src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/logo/ceynak.png" alt="">
                    <img class="logo-hover" src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/logo/ceynak.png" alt="">
                </div>
            </div>
            <div class="item">
                <div class="clients-logo">
                    <img class="logo-main" src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/logo/cukurovaisi.png" alt="">
                    <img class="logo-hover" src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/logo/cukurovaisi.png" alt="">
                </div>
            </div>
            <div class="item">
                <div class="clients-logo">
                    <img class="logo-main" src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/logo/denizbank.png" alt="">
                    <img class="logo-hover" src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/logo/denizbank.png" alt="">
                </div>
            </div>
            <div class="item">
                <div class="clients-logo">
                    <img class="logo-main" src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/logo/enerjisa.svg" alt="">
                    <img class="logo-hover" src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/logo/enerjisa.svg" alt="">
                </div>
            </div>
            <div class="item">
                <div class="clients-logo">
                    <img class="logo-main" src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/logo/fibabanka.png" alt="">
                    <img class="logo-hover" src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/logo/fibabanka.png" alt="">
                </div>
            </div>
            <div class="item">
                <div class="clients-logo">
                    <img class="logo-main" src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/logo/finansbank.png" alt="">
                    <img class="logo-hover" src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/logo/finansbank.png" alt="">
                </div>
            </div>
            <div class="item">
                <div class="clients-logo">
                    <img class="logo-main" src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/logo/sakasu.png" alt="">
                    <img class="logo-hover" src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/logo/sakasu.png" alt="">
                </div>
            </div>
            <div class="item">
                <div class="clients-logo">
                    <img class="logo-main" src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/logo/sekerbank.webp" alt="">
                    <img class="logo-hover" src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/logo/sekerbank.webp" alt="">
                </div>
            </div>
            <div class="item">
                <div class="clients-logo">
                    <img class="logo-main" src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/logo/sodexo.svg" alt="">
                    <img class="logo-hover" src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/logo/sodexo.svg" alt="">
                </div>
            </div>
        </div>
    </div>
</div>