import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { SliderModule } from '../../organisms/slider/slider.module';
import { ClientLogoModule } from '../../organisms/client-logo/client-logo.module';
import { AboutModule } from '../../organisms/about/about.module';
import { TeamModule } from '../../organisms/team/team.module';
import { FeatureModule } from '../../organisms/feature/feature.module';
import { ContactModule } from '../../organisms/contact/contact.module';
import { ProjectsModule } from '../../organisms/projects/projects.module';

@NgModule({
  declarations: [HomeComponent],
  imports: [
    CommonModule,
    SliderModule,
    ClientLogoModule,
    AboutModule,
    TeamModule,
    FeatureModule,
    ContactModule,
    ProjectsModule
  ],
  exports: [HomeComponent],
})
export class HomeModule {
  
}
