<!-- Fetures -->
<section class="content-inner-2" id="how">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-xl-6 col-lg-7 wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.2s">
                <div class="section-head style-1">
                    <h6 class="sub-title">İş Akışı</h6>
                    <h2 class="title">Nasıl Çalışıyoruz?</h2>
                </div>
                <div class="section-wraper-one">
                    <div class="icon-bx-wraper style-2 left m-b30">
                        <div class="icon-bx-md radius bg-white text-red"> 
                            <a href="javascript:void(0);" class="icon-cell">
                                <i class="flaticon-idea"></i>
                            </a> 
                        </div>
                        <div class="icon-content">
                            <h4 class="dlab-title">Proje Planlama</h4>
                            <p>Sizlerle bir araya gelerek, projenizin tamamı hakkında ayrıntılı bir toplantı gerçekleştiriyoruz. Bu toplantıda, iş ihtiyaçlarınızı, beklentilerinizi ve hedeflerinizi belirleyerek sizlere en uygun çözümleri sunuyoruz. Uzman ekibimiz, belirlediğimiz ihtiyaçlar doğrultusunda en uygun teknolojileri ve çözümleri seçerek projenizin en verimli şekilde ilerlemesini sağlıyor.</p>
                        </div>
                    </div>
                    <div class="icon-bx-wraper style-2 left m-b30">
                        <div class="icon-bx-md radius bg-white text-yellow"> 
                            <a href="javascript:void(0);" class="icon-cell">
                                <i class="flaticon-line-graph"></i>
                            </a> 
                        </div>
                        <div class="icon-content">
                            <h4 class="dlab-title">Tasarım & Geliştirme</h4>
                            <p>Müşterimizin iş ihtiyaçlarına uygun en iyi teknoloji ve çözümleri belirledikten sonra, kullanıcı dostu tasarımlar ve özelleştirilmiş yazılım çözümleri geliştiriyoruz. Geliştirme sürecinde, uzman ekibimiz kullanıcı deneyimine önem vererek projenin tüm ayrıntılarını ele alıyor ve müşterimizin ihtiyaçlarına en uygun çözümü oluşturuyor.</p>
                        </div>
                    </div>
                    <div class="icon-bx-wraper style-2 left m-b30">
                        <div class="icon-bx-md radius bg-white text-green"> 
                            <a href="javascript:void(0);" class="icon-cell">
                                <i class="flaticon-rocket"></i>
                            </a> 
                        </div>
                        <div class="icon-content">
                            <h4 class="dlab-title">Kalite Kontrol ve Teslimat</h4>
                            <p>Tasarım ve geliştirme sürecinin tamamlanmasının ardından, ürünümüzü müşterimizin iş ihtiyaçlarına uygun olarak tasarladığımızı garanti etmek için kullanıcı kabul testi gerçekleştiriyoruz.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-lg-5 wow fadeInRight  " data-wow-duration="2s" data-wow-delay="0.4s">
                <div class="dlab-media cf-r-img d-lg-block d-none">
                    <img src="/assets/images/about/pic1.png" class="move-2" alt="">
                </div>
            </div>
        </div>
    </div>
</section>