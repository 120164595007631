<!-- Team -->
<section class="content-inner-1 bgl-primary" id="team">
    <div class="container">
        <div class="section-head style-1 text-center">
            <h6 class="sub-title">Bu harika işleri kim yapıyor?</h6>
            <h2 class="title">EKİP</h2>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="team-carousel1 owl owl-carousel owl-none owl-theme owl-dots-primary-full">
                    
                    <div class="item wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.3s">
                        <div class="dlab-team style-1 m-b20">
                            <div class="dlab-media">
                                <a href="javascript:void(0);"><img src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/team/aslan.png" alt=""></a>
                            </div>
                            <div class="dlab-content">
                                <div class="clearfix">
                                    <h4 class="dlab-name"><a href="javascript:void(0);">Aslan Süleyman</a></h4>
                                    <span class="dlab-position">Mid. Senior Fullstack Developer</span>
                                </div>
                                <ul class="dlab-social-icon primary-light">
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    <div class="item wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.3s">
                        <div class="dlab-team style-1 m-b20">
                            <div class="dlab-media">
                                <a href="javascript:void(0);"><img src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/team/daniel.jpg" alt=""></a>
                            </div>
                            <div class="dlab-content">
                                <div class="clearfix">
                                    <h4 class="dlab-name"><a href="javascript:void(0);">Daniel Ashaya</a></h4>
                                    <span class="dlab-position">Senior Backend Developer</span>
                                </div>
                                <ul class="dlab-social-icon primary-light">
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="item wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.1s">
                        <div class="dlab-team style-1 m-b20">
                            <div class="dlab-media">
                                <a href="javascript:void(0);"><img src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/team/engin.jpg" alt=""></a>
                            </div>
                            <div class="dlab-content">
                                <div class="clearfix">
                                    <h4 class="dlab-name"><a href="javascript:void(0);">Can Canbolat</a></h4>
                                    <span class="dlab-position">Team Leader & Full Stack Developer</span>
                                </div>
                                <ul class="dlab-social-icon primary-light">
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="item wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                        <div class="dlab-team style-1 m-b20">
                            <div class="dlab-media">
                                <a href="javascript:void(0);"><img src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/team/fatih.jpg" alt=""></a>
                            </div>
                            <div class="dlab-content">
                                <div class="clearfix">
                                    <h4 class="dlab-name"><a href="javascript:void(0);">Fatih Kartal</a></h4>
                                    <span class="dlab-position">Mid. Senior Backend Developer</span>
                                </div>
                                <ul class="dlab-social-icon primary-light">
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="item wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.4s">
                        <div class="dlab-team style-1 m-b20">
                            <div class="dlab-media">
                                <a href="javascript:void(0);"><img src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/team/gursel.jpg" alt=""></a>
                            </div>
                            <div class="dlab-content">
                                <div class="clearfix">
                                    <h4 class="dlab-name"><a href="javascript:void(0);">Gürsel Sayat</a></h4>
                                    <span class="dlab-position">CEO</span>
                                </div>
                                <ul class="dlab-social-icon primary-light">
                                    <li><a href="https://www.linkedin.com/in/gursel-sayat-284bb13a/" target="_blank" class="fab fa-linkedin"></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</section>			