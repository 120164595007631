<!-- Get A Quote -->
<section class="content-inner" id="contact" style="background-image: url(assets/images/background/bg19.png); background-size:100%; background-position:center; background-repeat:no-repeat;" id="contact">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-xl-6 col-lg-7 m-b30 wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.2s">
                <div class="section-head style-1">
                    <h6 class="sub-title">Bir Mesajınız Yeter</h6>
                    <h2 class="title">İletişim</h2>
                </div>
              
                    
                <div class="row mt-5">
					<div class="col-lg-6 col-md-6">
						<div class="icon-bx-wraper style-9 m-md-b60  m-sm-t30">
							<div class="icon-bx-sm radius bg-primary"> 
								<a href="javascript:void(0);" class="icon-cell">
									<i class="las la-map-marker"></i>
								</a> 
							</div>
							<div class="icon-content">
								<h4 class="dlab-title">Adres</h4>
								<p>Akarsu Yokuşu Cad. Işık Apt. 29/3 Cihangir Beyoğlu / İstanbul</p>
							</div>
						</div>
					</div>
					<div class="col-lg-6 col-md-6">
						<div class="icon-bx-wraper style-9  m-md-t30">
							<div class="icon-bx-sm radius bg-primary"> 
								<a href="javascript:void(0);" class="icon-cell">
									<i class="las la-envelope-open"></i>
								</a> 
							</div>
							<div class="icon-content">
								<h4 class="dlab-title">E-Posta</h4>
								<p><a href="mailto:info@bfydigital.com">info@bfydigital.com</a></p>
							</div>
						</div>
					</div>
				</div>

				<div class="row mt-5">
				<div class="col-lg-6 col-md-6">
					<div class="icon-bx-wraper style-9  m-md-t30">
						<div class="icon-bx-sm radius bg-primary"> 
							<a href="javascript:void(0);" class="icon-cell">
								<i class="las la-hashtag"></i>
							</a> 
						</div>
						<div class="icon-content">
							<h4 class="dlab-title">Sosyal Ağlar</h4>
							<ul>
								<li><a href="https://github.com/BFYDigital" target="_blank">GIT HUB</a></li>
								<li><a href="https://www.linkedin.com/company/bfy-digital-bilgi-teknolojileri" target="_blank">Linkedin</a></li>
								<li><a href="https://www.twitter.com/bfydigital" target="_blank">Twitter</a></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
              
            </div>
            <div class="col-xl-6 col-lg-5 m-b30 wow fadeInRight" data-wow-duration="2s" data-wow-delay="0.4s">
                <div class="dlab-media cf-r-img">	
                    <img src="/assets/images/about/pic2.png" class="move-1" alt="">
                </div>
            </div>
        </div>
    </div>
</section>