
<!-- Projects -->
<section class="content-inner-1" id="projects">
    <div class="container-fluid">
        <div class="section-head style-1 text-center mb-3">
            <h6 class="sub-title">Neler Yaptık</h6>
            <h2 class="title">Referanslarımız</h2>
        </div>
        <div class="site-filters style-1 clearfix center mb-5">
            <ul class="filters" data-bs-toggle="buttons">
                <li class="btn active">
                    <input type="radio">
                    <a href="javascript:void(0);">Hepsi</a> 
                </li>
                <li data-filter=".web" class="btn">
                    <input type="radio">
                    <a href="javascript:void(0);">Web</a> 
                </li>
                <li data-filter=".mobile" class="btn">
                    <input type="radio">
                    <a href="javascript:void(0);">Mobil</a> 
                </li>
                <li data-filter=".desktop" class="btn">
                    <input type="radio">
                    <a href="javascript:void(0);">Masaüstü</a> 
                </li>
                <li data-filter=".other" class="btn">
                    <input type="radio">
                    <a href="javascript:void(0);">Diğer</a> 
                </li>
            </ul>
        </div>
        <div class="clearfix">
            <ul id="masonry"  class="row lightgallery">
                <li class="card-container col-lg-3 col-md-6 col-sm-6 mobile fadeInUp" data-wow-duration="2s" data-wow-delay="0.8s">
                    <div class="dlab-box dlab-overlay-box style-1 m-b30 thumbnail">
                        <div class="dlab-media dlab-img-overlay1 primary">
                            <img src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/projects/saka.jpg" alt="">
                        </div>
                        <div class="dlab-info">
                            <h4 class="title">
                                <a href="/assets/images/projects/saka.jpg" data-exthumbimage="/assets/images/projects/saka.jpg" class="lightimg" title="Saka Su Mobil Uygulaması">
                                    Saka Su Mobil Uygulaması
                                </a>
                            </h4>
                            <ul class="tags-list">
                                <li><a href="#">Ajans: Artbar</a></li>
                                <li><a href="#">Hizmet: Mobil uygulama geliştirme</a></li>
                            </ul>
                        </div>
                    </div>
                </li>

                <li class="card-container col-lg-3 col-md-6 col-sm-6 mobile fadeInUp" data-wow-duration="2s" data-wow-delay="0.8s">
                    <div class="dlab-box dlab-overlay-box style-1 m-b30 thumbnail">
                        <div class="dlab-media dlab-img-overlay1 primary">
                            <img src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/projects/liqua.jpg" alt="">                        
                        </div>
                        <div class="dlab-info">
                            <h4 class="title">
                                <a href="/assets/images/projects/liqua.jpg" data-exthumbimage="/assets/images/projects/liqua.jpg" class="lightimg" title="Liqua Trans Mobil Uygulaması">
                                    Liqua Trans Mobil Uygulaması
                                </a>
                            </h4>
                            <ul class="tags-list">
                                <li><a href="#">Ajans: DDIP</a></li>
                                <li><a href="#">Hizmet: Mobil uygulama geliştirme</a></li>
                            </ul>
                        </div>
                    </div>
                </li>

                <li class="card-container col-lg-3 col-md-6 col-sm-6 web fadeInUp" data-wow-duration="2s" data-wow-delay="0.8s">
                    <div class="dlab-box dlab-overlay-box style-1 m-b30 thumbnail">
                        <div class="dlab-media dlab-img-overlay1 primary">
                            <img src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/projects/aksigorta.png" alt="">
                        </div>
                        <div class="dlab-info">
                            <h4 class="title">
                                <a href="/assets/images/projects/aksigorta.jpg" data-exthumbimage="/assets/images/projects/aksigorta.jpg" class="lightimg" title="Aksigorta Web Sitesi">
                                    Aksigorta Web Sitesi
                                </a>
                            </h4>
                            <ul class="tags-list">
                                <li><a href="#">Ajans: AgencyLook</a></li>
                                <li><a href="#">Hizmet: CMS Geliştirme</a></li>
                            </ul>
                        </div>
                    </div>
                </li>

                <li class="card-container col-lg-3 col-md-6 col-sm-6 web fadeInUp" data-wow-duration="2s" data-wow-delay="0.8s">
                    <div class="dlab-box dlab-overlay-box style-1 m-b30 thumbnail">
                        <div class="dlab-media dlab-img-overlay1 primary">
                            <img src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/projects/efinans.png" alt="">
                        </div>
                        <div class="dlab-info">
                            <h4 class="title">
                                <a href="/assets/images/projects/efinans.png" data-exthumbimage="/assets/images/projects/efinans.png" class="lightimg" title="eFinans Web Sitesi">
                                    eFinans Web Sitesi
                                </a>
                            </h4>
                            <ul class="tags-list">
                                <li><a href="#">Ajans: AgencyLook</a></li>
                                <li><a href="#">Hizmet: CMS Geliştirme</a></li>
                            </ul>
                        </div>
                    </div>
                </li>

                <li class="card-container col-lg-3 col-md-6 col-sm-6 web fadeInUp" data-wow-duration="2s" data-wow-delay="0.8s">
                    <div class="dlab-box dlab-overlay-box style-1 m-b30 thumbnail">
                        <div class="dlab-media dlab-img-overlay1 primary">
                            <img src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/projects/alternatifbank.png" alt="">
                        </div>
                        <div class="dlab-info">
                            <h4 class="title">
                                <a href="/assets/images/projects/alternatifbank.png" data-exthumbimage="/assets/images/projects/alternatifbank.png" class="lightimg" title="Alternatif Bank Web Sitesi">
                                    Alternatif Bank Web Sitesi
                                </a>
                            </h4>
                            <ul class="tags-list">
                                <li><a href="#">Ajans: AgencyLook</a></li>
                                <li><a href="#">Hizmet: CMS Geliştirme</a></li>
                            </ul>
                        </div>
                    </div>
                </li>

                <li class="card-container col-lg-3 col-md-6 col-sm-6 web fadeInUp" data-wow-duration="2s" data-wow-delay="0.8s">
                    <div class="dlab-box dlab-overlay-box style-1 m-b30 thumbnail">
                        <div class="dlab-media dlab-img-overlay1 primary">
                            <img src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/projects/tosla.png" alt="">
                        </div>
                        <div class="dlab-info">
                            <h4 class="title">
                                <a href="/assets/images/projects/tosla.png" data-exthumbimage="/assets/images/projects/tosla.png" class="lightimg" title="Tosla Web Sitesi">
                                    Tosla Web Sitesi
                                </a>
                            </h4>
                            <ul class="tags-list">
                                <li><a href="#">Ajans: AgencyLook</a></li>
                                <li><a href="#">Hizmet: CMS Geliştirme</a></li>
                            </ul>
                        </div>
                    </div>
                </li>

                <li class="card-container col-lg-3 col-md-6 col-sm-6 desktop fadeInUp" data-wow-duration="2s" data-wow-delay="0.8s">
                    <div class="dlab-box dlab-overlay-box style-1 m-b30 thumbnail">
                        <div class="dlab-media dlab-img-overlay1 primary">
                            <img src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/projects/ozbey.jpg" alt="">
                        </div>
                        <div class="dlab-info">
                            <h4 class="title">
                                <a href="/assets/images/projects/ozbey.jpg" data-exthumbimage="/assets/images/projects/ozbey.jpg" class="lightimg" title="Özbey Finans Platformu">
                                    Özbey Finans Platformu
                                </a>
                            </h4>
                            <ul class="tags-list">
                                <li><a href="#">Müşteri: Ağabey Holding</a></li>
                                <li><a href="#">Hizmet: Yazılım</a></li>
                            </ul>
                        </div>
                    </div>
                </li>

                <li class="card-container col-lg-3 col-md-6 col-sm-6 web fadeInUp" data-wow-duration="2s" data-wow-delay="0.8s">
                    <div class="dlab-box dlab-overlay-box style-1 m-b30 thumbnail">
                        <div class="dlab-media dlab-img-overlay1 primary">
                            <img src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/projects/alarko.png" alt="">
                        </div>
                        <div class="dlab-info">
                            <h4 class="title">
                                <a href="/assets/images/projects/alarko.png" data-exthumbimage="/assets/images/projects/alarko.png" class="lightimg" title="Alarko Web Sitesi">
                                    Alarko Web Sitesi
                                </a>
                            </h4>
                            <ul class="tags-list">
                                <li><a href="#">Ajans: AgencyLook</a></li>
                                <li><a href="#">Hizmet: CMS Geliştirme</a></li>
                            </ul>
                        </div>
                    </div>
                </li>

                <li class="card-container col-lg-3 col-md-6 col-sm-6 web fadeInUp" data-wow-duration="2s" data-wow-delay="0.8s">
                    <div class="dlab-box dlab-overlay-box style-1 m-b30 thumbnail">
                        <div class="dlab-media dlab-img-overlay1 primary">
                            <img src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/projects/calik.png" alt="">
                        </div>
                        <div class="dlab-info">
                            <h4 class="title">
                                <a href="/assets/images/projects/calik.png" data-exthumbimage="/assets/images/projects/calik.png" class="lightimg" title="Çalık Web Sitesi">
                                    Çalık Web Sitesi
                                </a>
                            </h4>
                            <ul class="tags-list">
                                <li><a href="#">Ajans: AgencyLook</a></li>
                                <li><a href="#">Hizmet: CMS Geliştirme</a></li>
                            </ul>
                        </div>
                    </div>
                </li>

                <li class="card-container col-lg-3 col-md-6 col-sm-6 web fadeInUp" data-wow-duration="2s" data-wow-delay="0.8s">
                    <div class="dlab-box dlab-overlay-box style-1 m-b30 thumbnail">
                        <div class="dlab-media dlab-img-overlay1 primary">
                            <img src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/projects/cukurovaisi.png" alt="">
                        </div>
                        <div class="dlab-info">
                            <h4 class="title">
                                <a href="/assets/images/projects/cukurovaisi.png" data-exthumbimage="/assets/images/projects/cukurovaisi.png" class="lightimg" title="Çukurova Isı Web Sitesi">
                                    Çukurova Isı Web Sitesi
                                </a>
                            </h4>
                            <ul class="tags-list">
                                <li><a href="#">Ajans: Artbar</a></li>
                                <li><a href="#">Hizmet: CMS Geliştirme</a></li>
                            </ul>
                        </div>
                    </div>
                </li>

                <li class="card-container col-lg-3 col-md-6 col-sm-6 web fadeInUp" data-wow-duration="2s" data-wow-delay="0.8s">
                    <div class="dlab-box dlab-overlay-box style-1 m-b30 thumbnail">
                        <div class="dlab-media dlab-img-overlay1 primary">
                            <img src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/projects/ceynak.png" alt="">
                        </div>
                        <div class="dlab-info">
                            <h4 class="title">
                                <a href="/assets/images/projects/ceynak.png" data-exthumbimage="/assets/images/projects/ceynak.png" class="lightimg" title="Cey Group Web Siteleri">
                                    Cey Group Web Siteleri
                                </a>
                            </h4>
                            <ul class="tags-list">
                                <li><a href="#">Ajans: Artbar</a></li>
                                <li><a href="#">Hizmet: CMS Geliştirme</a></li>
                            </ul>
                        </div>
                    </div>
                </li>

                <li class="card-container col-lg-3 col-md-6 col-sm-6 web fadeInUp" data-wow-duration="2s" data-wow-delay="0.8s">
                    <div class="dlab-box dlab-overlay-box style-1 m-b30 thumbnail">
                        <div class="dlab-media dlab-img-overlay1 primary">
                            <img src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/projects/fibabanka.png" alt="">
                        </div>
                        <div class="dlab-info">
                            <h4 class="title">
                                <a href="/assets/images/projects/fibabanka.png" data-exthumbimage="/assets/images/projects/fibabanka.png" class="lightimg" title="Fiba Banka Web Sitesi">
                                    Fiba Banka Web Sitesi
                                </a>
                            </h4>
                            <ul class="tags-list">
                                <li><a href="#">Ajans: Agencylook</a></li>
                                <li><a href="#">Hizmet: CMS Geliştirme</a></li>
                            </ul>
                        </div>
                    </div>
                </li>

                <li class="card-container col-lg-3 col-md-6 col-sm-6 web fadeInUp" data-wow-duration="2s" data-wow-delay="0.8s">
                    <div class="dlab-box dlab-overlay-box style-1 m-b30 thumbnail">
                        <div class="dlab-media dlab-img-overlay1 primary">
                            <img src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/projects/sodexo.png" alt="">
                        </div>
                        <div class="dlab-info">
                            <h4 class="title">
                                <a href="/assets/images/projects/sodexo.png" data-exthumbimage="/assets/images/projects/sodexo.png" class="lightimg" title="Sodexo Web Sitesi">
                                    Sodexo Web Sitesi
                                </a>
                            </h4>
                            <ul class="tags-list">
                                <li><a href="#">Ajans: Agencylook</a></li>
                                <li><a href="#">Hizmet: CMS Geliştirme</a></li>
                            </ul>
                        </div>
                    </div>
                </li>

                <li class="card-container col-lg-3 col-md-6 col-sm-6 mobile fadeInUp" data-wow-duration="2s" data-wow-delay="0.8s">
                    <div class="dlab-box dlab-overlay-box style-1 m-b30 thumbnail">
                        <div class="dlab-media dlab-img-overlay1 primary">
                            <img src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/projects/ozbeymobil.png" alt="">
                            <span data-exthumbimage="/assets/images/projects/ozbeymobil.png" data-src="/assets/images/projects/ozbeymobil.png" class="lightimg" title="Design">		
                                <i class="la la-plus"></i> 
                            </span>
                        </div>
                        <div class="dlab-info">
                            <h4 class="title">
                                <a href="/assets/images/projects/ozbeymobil.png" data-exthumbimage="/assets/images/projects/ozbeymobil.png" class="lightimg" title="Özbey Finans Mobil Uygulaması">
                                    Özbey Finans Mobil Uygulaması
                                </a>
                            </h4>
                            <ul class="tags-list">
                                <li><a href="#">Müşteri: Ağabey Holding</a></li>
                                <li><a href="#">Hizmet: Mobil Uygulama Geliştirme</a></li>
                            </ul>
                        </div>
                    </div>
                </li>

                <li class="card-container col-lg-3 col-md-6 col-sm-6 other fadeInUp" data-wow-duration="2s" data-wow-delay="0.8s">
                    <div class="dlab-box dlab-overlay-box style-1 m-b30 thumbnail">
                        <div class="dlab-media dlab-img-overlay1 primary">
                            <img src="https://res.cloudinary.com/dwv4ynuiv/image/fetch/f_auto/w_350/https://testweb.bfydigital.com/assets/images/projects/genero.png" alt="">
                        </div>
                        <div class="dlab-info">
                            <h4 class="title">
                                <a href="/assets/images/projects/genero.png" data-exthumbimage="/assets/images/projects/genero.png" class="lightimg" title="Genero Mobil Oyunu">
                                    Genero Mobil Oyunu
                                </a>
                            </h4>
                            <ul class="tags-list">
                                <li><a href="#">Müşteri: Enerjisa</a></li>
                                <li><a href="#">Hizmet: Oyun Geliştirme</a></li>
                            </ul>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</section>