import {
  Component,
  OnInit,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'app-client-logo',
  templateUrl: './client-logo.component.html',
  styleUrls: ['./client-logo.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ClientLogoComponent implements OnInit {
  constructor() {
  }

  ngOnInit(): void {
    
  }
}
