<!-- About Us -->
<section class="content-inner" style="background-image: url(assets/images/background/bg19.png); background-size:100%;"
    id="about">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-6 m-b30 wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.2s">
                <div class="dz-media">
                    <img src="/assets/images/about/img4.png" class="move-1" alt="">
                </div>
            </div>
            <div class="col-md-6 m-b30 wow fadeInRight" data-wow-duration="2s" data-wow-delay="0.4s">
                <div class="section-head style-1 mb-4">
                    <h6 class="sub-title">Biz Kimiz?</h6>
                    <h3 class="title"></h3>
                </div>
                <p class="m-b20">
                    BFY Digital, 2011 yılından bu yana yazılım sektöründe en iyi işleri yapmak için çalışıyor.
                    <br />Bizler, işimizi tutkuyla yapan butik bir yazılım ekibiyiz ve müşterilerimize yeni nesil yazılım
                    teknolojilerini sunuyoruz. Türkiye'nin en büyük markalarına sağladığımız hizmetlerin deneyimi ile,
                    her zaman daha fazlasını yapmanın peşindeyiz.
                </p>
                <p class="m-b20">
                    Özellikle içerik yönetim sistemi ve mobil uygulama geliştirme konularında uzmanız. Ayrıca blockchain
                    tabanlı akıllı sözleşmeler, oyun geliştirme ve finans uygulamaları gibi birçok alanda da başarılı
                    projelere imza attık.
                </p>
                <!-- <a data-wow-duration="1.6s" data-wow-delay="2s" href="#contact" (click)="goSection($event, 'team')"
                    class="wow fadeInUp btn btn-primary text-white">Ekibimiz<i class="fa fa-angle-right m-l10"></i></a> -->
            </div>
        </div>
    </div>
</section>