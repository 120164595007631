<!-- Header -->
<header class="site-header header header-transparent text-black mo-left">
	<!-- Main Header -->
	<div class="sticky-header main-bar-wraper navbar-expand-lg">
		<div class="main-bar clearfix ">
			<div class="container clearfix">
				<!-- Website Logo -->
				<div class="logo-header  logo-dark">
					<a href="/"><img src="/assets/images/logo.png" alt=""></a>
				</div>
				<!-- Nav Toggle Button -->
				<button class="navbar-toggler collapsed navicon justify-content-end" type="button"
					data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
					aria-expanded="false" aria-label="Toggle navigation">
					<span></span>
					<span></span>
					<span></span>
				</button>
				<div class="header-nav navbar-collapse collapse justify-content-end" id="navbarNavDropdown">
					<div class="logo-header">
						<a href="index.html"><img src="/assets/images/logo.png" alt=""></a>
					</div>
					<ul class="nav navbar-nav navbar">
						<li class="active scroll"><a href="#start" (click)="goSection($event, 'main')"><span>BFY Digital</span></a>
						</li>
						<li class="scroll"><a href="#neler-yaptik" (click)="goSection($event, 'projects')"><span>Neler Yaptık</span></a>
						</li>
						<li class="scroll"><a href="#nasil"  (click)="goSection($event, 'how')"><span>Nasıl Yapıyoruz</span></a>
						</li>
						<!-- <li class="scroll"><a href="#ekip"  (click)="goSection($event, 'team')"><span>Ekibimiz</span></a>
						</li> -->
						<li class="scroll"><a href="#contact"  (click)="goSection($event, 'contact')"><span>İletişim</span></a>
						</li>
					</ul>
					<div class="dlab-social-icon">
						<ul>
							<li><a class="fab fa-facebook-f" href="javascript:void(0);"></a></li>
							<li><a class="fab fa-twitter" href="javascript:void(0);"></a></li>
							<li><a class="fab fa-linkedin-in" href="javascript:void(0);"></a></li>
							<li><a class="fab fa-instagram" href="javascript:void(0);"></a></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- Main Header End -->
</header>
<!-- Header End -->