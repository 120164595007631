import {
  Component,
  OnInit,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SliderComponent implements OnInit {
  constructor() {
  }

  ngOnInit(): void {
    
  }

  goSection($event: MouseEvent, id: string)
  {    
    $event.preventDefault();
    $event.stopPropagation();
    var element: HTMLElement|null = document.getElementById(id);
    if(element)
    {
      element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }
  }
}
