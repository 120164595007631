import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientLogoComponent } from './client-logo.component';

@NgModule({
  declarations: [ClientLogoComponent],
  imports: [
    CommonModule,
  ],
  exports: [ClientLogoComponent],
})
export class ClientLogoModule {
  
}
