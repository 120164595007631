<app-slider></app-slider>

<section style="background-image: url(assets/images/background/bg1.png); background-size:100%;">
    <app-client-logo></app-client-logo>
</section>

<app-projects id="neler-yaptik"></app-projects>


<app-feature></app-feature>


<app-about></app-about>
<!--
<app-team></app-team>
-->
<app-contact id="contact"></app-contact>

<ng-template #components></ng-template>