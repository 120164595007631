<!-- Footer -->
<footer class="site-footer style-1" id="footer">
    <!-- footer bottom part -->
    <div class="footer-bottom wow fadeIn" data-wow-duration="2s" data-wow-delay="0.2s">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-center"> 
                    <span class="copyright-text">Copyright © 2022 BFY Digital Bilgi Tek. Ltd. Şti.</span> 
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- Footer End -->